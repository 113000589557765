import React, { useState, useRef } from 'react';
import { Button, Row, Col, Space, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import CreateWxpayAccount from './create_wxpay_account';
// import WxpayAccountQueryDrawerForm from './wxpay_account_query_form';
import WxpayAccountList from './wxpay_account_list';
// import WxpayAccountQueryParams from './wxpay_account_query_params_display';
// WxpayAccount 入口组件

const WxpayAccountComponent = () => {
  const reqParams = useRef({ page: 0 });

  const [reloadListCount, setReloadListCount] = useState(1);

  // const [drawerVisible, setDrawerVisible] = useState(false);

  const [createWxpayAccountVisible, setCreateWxpayAccountVisible] = useState(false);

  // const queryHandle = (currentRequestParams) => {
  //   // 查询操作
  //   reqParams.current = { ...currentRequestParams };
  //   // 触发重新读取数据
  //   setReloadListCount(reloadListCount + 1)
  //   console.log('search...');
  // };

  const initReloadList = () => {
    // 刷新列表, 删除所有查询条件
    reqParams.current = { page: 0 };
    setReloadListCount(reloadListCount + 1);
  }

  return (
    <div>
      <Row>
        <Col span={14}>
          <Space>
            <h4>WxpayAccount</h4>
            <Tooltip title="刷新">
              <Button onClick={initReloadList} shape="circle" icon={<ReloadOutlined />}></Button>
            </Tooltip>
            {/* <Tooltip title="搜索">
              <Button onClick={() => setDrawerVisible(true)} shape="circle" icon={<SearchOutlined />}></Button>
            </Tooltip> */}
          </Space>
        </Col>
        {/* <Col span={10}>
          <div style={{ width: '100%', height: 'auto' }}>
            <Button style={{ float: 'right' }} type="link" onClick={() => setCreateWxpayAccountVisible(true)}>新建</Button>
          </div>
        </Col> */}
      </Row>
      {/* <Row>
        <Col span={24}><WxpayAccountQueryParams paramsObj={reqParams.current}></WxpayAccountQueryParams></Col>
      </Row>
      <WxpayAccountQueryDrawerForm
        visible={drawerVisible}
        setVisible={setDrawerVisible}
        queryHandle={queryHandle}
      ></WxpayAccountQueryDrawerForm> */}

      <WxpayAccountList
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
        requestParams={reqParams.current}
      ></WxpayAccountList>

      <CreateWxpayAccount
        visible={createWxpayAccountVisible}
        setVisible={setCreateWxpayAccountVisible}
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
      ></CreateWxpayAccount>
    </div>
  )
};

export default WxpayAccountComponent;
