import React from 'react';
// import { DatePicker } from 'antd';
// import { CustomSelect } from '../../base_components/select_component';
import ListsItem from '../../base_components/list_item';

// WxpayAccount 列 配置

// ~~~~~~ 表单列, 需添加 ~~~~~~
const formKeys = ["company_name", "app_id", "app_secret", "message_token", "message_encoding_aeskey", "mch_id", "api_key", "apiv3_key"];

// ~~~~~~ 表单不能编辑列, 根据实际添加 ~~~~~~
const formDisabledKeys = [];

// ~~~~~~ 表单不显示列, 根据实际添加 ~~~~~~
const formNotShowKeys = ["app_secret", "message_token", "message_encoding_aeskey"];

// ~~~~~~ 表单列名称 label, 需添加 ~~~~~~
const formKeysLabelsObj = {
  "company_name": "公司名称", "app_id": "小程序appid", "app_secret": "微信支付密钥", "message_token": "信息token",
  "message_encoding_aeskey": "aeskey", "mch_id":"微信支付商户号", "api_key": "API密钥", "apiv3_key": "APIv3密钥"};

//  ~~~~~~ 选择框, 根据实际添加 ~~~~~~
// const SelectEndTime = (<DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />);
// const SelectBoolean = (<CustomSelect options={[{ value: 0, label: '否' }, { value: 1, label: '是' }]} />);
const formKeySelectComponentsObj = {
  // 'end_time': SelectEndTime,
  // exampleColumn: SelectBoolean,
};

//  ~~~~~~ 表单是图片列, 根据实际添加 ~~~~~~
const formKeysIsImages = [];

//  ~~~~~~ 表单get 请求条件key, 根据实际添加 ~~~~~~
const formGetRequestParamsKeys = [];

//  ~~~~~~ 表单初始化值, 根据实际添加 ~~~~~~
const formInitialObj = {}

// ~~~~~~ 图片列名称, 根据实际修改 ~~~~~~
const imageKeyNames = [];

// ~~~~~~ 图片总数量, 默认设置为1, 根据实际修改 ~~~~~~
const maxImagesNum = 0;

// ~~~~~~ api请求url apiLists, 根据实际修改 ~~~~~~
const apiLists = ['wxpay-admin/v1/companywxpayinfo', ];

// 修改 ~~~~~~~ item keys ~~~~~~;
const ItemRendererComponent = ({ item, index, itemDetailClick }) => {
  // 必须 每一行渲染 的组件, 修改 ~~~~~~~ item keys ~~~~~~;
  console.log(item);
  return (<div>
    <ListsItem
      listsIndex={index}
      productTitle={item.company_name}
      productEffects={`小程序: app_id(${item.app_id})`}
      salePrice={0}
      clickDetailHandle={itemDetailClick}
    ></ListsItem>
  </div>);
};

export {
  formKeys,
  formDisabledKeys,
  formNotShowKeys,
  formKeysLabelsObj,
  formKeySelectComponentsObj,
  formKeysIsImages,
  formGetRequestParamsKeys,
  formInitialObj,
  imageKeyNames,
  maxImagesNum,
  apiLists,
  ItemRendererComponent
};
