import React from 'react';

import AxiosRequest from '../../apis';
import { apiLists } from './wxpay_account_columns_label_config';

// WxpayAccount 的request请求

const WxpayAccountRequestGet = ({ requestCount, reqParams, responseDataHandle }) => {
  // WxpayAccount Get请求
  const api = apiLists[0];
  const method = 'get';
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
WxpayAccountRequestGet.defaultProps = {
  requestCount: 0,
  reqParams: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

const WxpayAccountRequestPost = ({ requestCount, reqObject, responseDataHandle }) => {
  // WxpayAccount post请求
  const api = [].concat(apiLists)[0];
  const method = 'post';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
WxpayAccountRequestPost.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
}

const WxpayAccountRequestPut = ({ requestCount, reqObject, responseDataHandle, id }) => {
  // WxpayAccount put请求
  const api = [].concat(apiLists)[0] + `/${id}`;
  const method = 'put';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
WxpayAccountRequestPut.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
  id: 0
}

const WxpayAccountRequestDelete = ({ requestCount, responseDataHandle, id }) => {
  // WxpayAccount delete请求
  const api = [].concat(apiLists)[0] + `/${id}`;
  const method = 'delete';
  const reqParams = {};
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
WxpayAccountRequestDelete.defaultProps = {
  requestCount: 0,
  responseDataHandle: (respObj) => { console.log(respObj); },
  id: 0
}

export {
  WxpayAccountRequestGet,
  WxpayAccountRequestPost,
  WxpayAccountRequestPut,
  WxpayAccountRequestDelete
};
