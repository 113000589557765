import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import WxpayAccountComponent from '../components/wx_store/wxpay_account';

const WxPayAccountPage = () => (
  <SiteLayout>
    <SEO title="WxPay" />
    <WxpayAccountComponent></WxpayAccountComponent>
  </SiteLayout>
)

export default WxPayAccountPage;
