import React, { useRef, useState } from 'react';
import { Modal, Divider, message } from 'antd';

import { StsUploadImagesList } from '../../upload_files/sts_upload_files_list';
import { WxpayAccountRequestPost } from './wxpay_account_requests';
import { CustomForm } from '../../base_components/form_component';

import {
  formKeys,
  formDisabledKeys,
  formNotShowKeys,
  formKeysLabelsObj,
  formKeySelectComponentsObj,
  formKeysIsImages,
  formGetRequestParamsKeys,
  formInitialObj,
  imageKeyNames,
  maxImagesNum
} from './wxpay_account_columns_label_config';

// 新建 Modal

const CreateWxpayAccount = ({ visible, setVisible, reloadListCount, setReloadListCount }) => {

  const wxpayAccountImageObj = useRef({});
  const oldObj = useRef({createCount: 0});

  const [initFormCount, setInitFormCount] = useState(0);

  const [postRequestCount, setPostRequestCount] = useState(0);
  const reqObject = useRef({});

  const postResponseDataHandle = (respObj) => {
    // 表单提交后的操作
    if (Object.keys(respObj).includes('code')
      && respObj.code === 1000) {
      closeModal();
      setReloadListCount(reloadListCount + 1)
    } else {
      message.error('网络错误...');
    }
  }

  const closeModal = () => {
    // 初始化表格和图片 并关闭Modal
    wxpayAccountImageObj.current = {};  // 清空图片数据
    oldObj.current = {createCount: oldObj.current.createCount + 1};  // 初始化图片
    setInitFormCount(initFormCount + 1);  // 初始化表格
    setVisible(false);
  }

  const wxpayAccountImageUploaded = (imagesObj) => {
    // 图片上传后操作
    wxpayAccountImageObj.current = { ...imagesObj };
  }

  const onFinish = (values) => {
    // 提交表单
    let upObj = { ...values, ...wxpayAccountImageObj.current };
    // let newDate = new Date(upObj.end_time);
    // upObj.end_time = parseInt(newDate.getTime());    // 时间转时间戳
    reqObject.current = upObj;
    setPostRequestCount(postRequestCount + 1);
  }

  return (
    <>
       <Modal visible={visible}
        title="WxpayAccount 新建"
        closable={false}
        footer={null}
      >
        <div style={{ width: '100%', height: 'auto', textAlign: 'center' }}>
          <StsUploadImagesList
            oldObj={oldObj.current}
            maxImagesNum={maxImagesNum}
            imageKeyName={imageKeyNames[0]}
            onChange={wxpayAccountImageUploaded}></StsUploadImagesList>
        </div>
        <Divider></Divider>

        <CustomForm
          columns={formKeys}
          disabledKeys={formDisabledKeys}
          notShowKeys={formNotShowKeys}
          formKeyLabelObj={formKeysLabelsObj}
          formKeySelectComponentsObj={formKeySelectComponentsObj}
          onFinish={onFinish}
          cancelHandle={closeModal}
          showDeleteButton={false}
          formInitialObj={formInitialObj}
          initFormCount={initFormCount}
        ></CustomForm>

      <WxpayAccountRequestPost
        requestCount={postRequestCount}
        reqObject={reqObject.current}
        responseDataHandle={postResponseDataHandle}></WxpayAccountRequestPost>
      </Modal>
    </>
  )
}

CreateWxpayAccount.defaultProps = {
  visible: false,
  setVisible: (val) => console.log(val),
  reloadListCount: 0,
  setReloadListCount: (booleanVal) => console.log(booleanVal)
}

export default CreateWxpayAccount;
